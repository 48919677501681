
import {getRequest,postRequest,getDownLoad,urlTitle} from "@/utils/request";
import { post } from "jquery";



//资产管理列表查询
export const assetInfoIndex=(query) => {
  return getRequest(`${urlTitle}/asset/info/index`,query);
}
//资产管理上传
export const assetInfoUPdateFile=(query)=>{
  return postRequest(`${urlTitle}/asset/info/importing`,query)
}
//模版下载
export const downloadTemplate=(query)=>{
  return getDownLoad(`${urlTitle}/asset/info/template`)
}
//新增资产
export const assetInfoAdd=(query)=>{
  return postRequest(`${urlTitle}/asset/info/save`,query)
}
//修改资产
export const assetInfoUpdate=(query)=>{
  return postRequest(`${urlTitle}/asset/info/update`,query)
}
//导出
export const assetInfoExport=(query)=>{
  return getDownLoad(`${urlTitle}/asset/info/excel`,query)
}
//删除
export const assetInfoDelete= (query)=>{
  return postRequest(`${urlTitle}/asset/info/remove/${query}`)
}
//字典查询
export const searchDictCodeList=(query)=>{
  return getRequest(`${urlTitle}/asset/info/getDictNameAndDictValue`,query)
}
//获取单位信息
export const unitList=()=>{
  return getRequest(`${urlTitle}/asset/info/getConpanyIdAndName`)
}

