<template>
  <div>
    <div class="content">
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">资产管理</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">

                <el-form-item label="设备名称：">
                  <!-- <el-input clearable v-model="formInline.equName" placeholder="请输入设备名称" class="custom-input"></el-input> -->
                  <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                    v-model="formInline.equName" placeholder="请选择">
                    <el-option v-for="(item, index) in searchList[2].list" :key="index" :label="item.dictName"
                      :value="item.dictValue"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备编号：">
                  <el-input clearable v-model="formInline.equNum" placeholder="请输入设备编号" class="custom-input"></el-input>
                </el-form-item>
                <el-form-item>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView">
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
              <el-button type="primary" @click="downloadFileTemplate" icon="el-icon-download"
                :class="isStringInArray('btnTemplateDownloadAdministration') ? '' : 'btnShowAuthority'"
                class="custom-button">模版下载</el-button>
              <el-button type="primary" @click="uploadFile"
                :class="isStringInArray('btnInportAdministration') ? '' : 'btnShowAuthority'" icon="el-icon-upload2"
                class="custom-button">导入</el-button>
              <el-button type="primary" @click="openViewDialog('0')" icon="el-icon-plus"
                :class="isStringInArray('btnNewEquAdministration') ? '' : 'btnShowAuthority'" class="custom-button">新建资产</el-button>
              <el-button type="primary" @click="clickTable('', 0)" icon="el-icon-download"
                :class="isStringInArray('btnExportAdministration') ? '' : 'btnShowAuthority'" class="custom-button">导出</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table v-loading="dataLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
            :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%"
            class="custom-table">
            <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="tableText">
                    {{ dataLoading ? '' : '暂无数据' }}
                  </div>
                </div>
              </div>
            </div>
            <el-table-column align="center" label="序号" type="index" :index="getIndex" width="100">
            </el-table-column>
            <el-table-column align="center" prop="equNameDict" label="设备名称" width="150">
              <!-- <template slot-scope="scope">
              {{ scope.row.equName |getName(searchList[2].list) }}
            </template> -->
            </el-table-column>
            <el-table-column align="center" prop="assetNum" label="资产编号" width="150">
            </el-table-column>
            <el-table-column align="center" prop="stationName" label="场站名称" width="200">
            </el-table-column>
            <el-table-column align="center" prop="stationNum" label="场站编码" width="150">
            </el-table-column>
            <el-table-column align="center" prop="equNum" label="设备编号" width="150">
            </el-table-column>
            <el-table-column align="center" prop="deviceOrder" label="设备数量" width="120">
            </el-table-column>
            <!-- <el-table-column align="center" prop="stationNickname" label="电站名称" width="100">
            </el-table-column> -->
            <el-table-column align="center" prop="transformerCapacity" label="变压器总容量（KVA）" width="200">
            </el-table-column>
            <el-table-column align="center" prop="typeDict" label="配电形式" width="200">
              <!-- <template slot-scope="scope">
                {{ scope.row.type |getName(searchList[0].list) }}
              </template> -->
            </el-table-column>
            <el-table-column align="center" prop="payUnit" label="缴费单位" width="200">
            </el-table-column>
            <el-table-column align="center" prop="positionTypeDict" label="安装位置" width="200">
              <!-- <template slot-scope="scope">
                {{ scope.row.positionTypeDesc | getName(searchList[1].list) }}
              </template> -->
            </el-table-column>
            <el-table-column align="center" prop="producerName" label="生产厂家" width="100">
            </el-table-column>
            <el-table-column align="center" prop="producerDate" label="生产日期" width="200">
            </el-table-column>
            <el-table-column align="center" prop="operationDate" label="投运日期" width="200">
            </el-table-column>
            <el-table-column align="center" prop="warrantyDate" label="质保截止日期" width="200">
            </el-table-column>

            <el-table-column align="center" prop="meteringTypeDict" label="计量方式" width="100">
              <!-- <template slot-scope="scope">
                {{ scope.row.meteringType |getName(searchList[3].list) }}
              </template> -->
            </el-table-column>
            <!-- <el-table-column align="center" prop="companyInfoIdDict" label="单位名称" width="100">
              <template slot-scope="scope">
                {{ scope.row.companyInfoId |getName(searchList[4].list) }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" prop="equModel" label="设备型号" width="100">
            </el-table-column>

            <el-table-column align="center" prop="data" fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button @click="replyDialog(scope.row, '2')" type="text">详情</el-button>
                <span :class="isStringInArray('btnModifyAdministration') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnModifyAdministration') ? '' : 'btnShowAuthority'"
                  @click="replyDialog(scope.row, '1')" type="text">编辑</el-button>
                <span :class="isStringInArray('btnModifyAdministration') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnDeleteAdministration') ? '' : 'btnShowAuthority'"
                  @click="clickTable(scope.row, 1)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn mouseCursor" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
            layout="prev, pager, next, sizes" :prev-text="prevButtonText" :next-text="nextButtonText"
            prev-button-class="custom-prev-button" next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm mouseCursor" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建弹窗 -->
    <el-dialog @close="dialogClose" :title="dialogTitle" :visible.sync="replyDialogVisible" width="40%">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="资产编号" prop="assetNum">
              <el-input :disabled="type == '2'" placeholder="请输入资产编号" clearable
                v-model.trim="ruleForm.assetNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="场站名称" prop="stationName">
              <el-input :disabled="type == '2'" placeholder="请输入场站名称" clearable
                v-model.trim="ruleForm.stationName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="场站编码" prop="stationNum">
              <el-input :disabled="type == '2'" placeholder="请输入场站编码" clearable
                v-model.trim="ruleForm.stationNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="电站名称" prop="stationNickname">
              <el-input :disabled="type == '2'" placeholder="请输入电站名称" clearable
                v-model.trim="ruleForm.stationNickname"></el-input>
            </el-form-item> -->
            <el-form-item label="设备数量:" prop="deviceOrder">
              <el-input :disabled="type == '2'" placeholder="请输入设备数量" clearable
                v-model.trim="ruleForm.deviceOrder"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="用电性质: " prop="">
              <el-input :disabled="type == '2'" placeholder="请输入用电性质" clearable
                v-model.trim="ruleForm.nature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外电源结构: " prop="">
              <!-- <el-input :disabled="type == '2'" placeholder="请输入外电源结构" clearable
                v-model.trim="ruleForm.electricStructure"></el-input> -->
                <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.electricStructure" placeholder="请输入外电源结构">
                <el-option v-for="(item, index) in searchList[4].list" :key="index" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="变压器总容量（KVA）" prop="transformerCapacity">
              <el-input :disabled="type == '2'" clearable placeholder="请输入变压器总容量"
                v-model.trim="ruleForm.transformerCapacity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配电形式" prop="type">
              <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.type" placeholder="请选择配电形式">
                <el-option v-for="(item, index) in searchList[0].list" :key="index" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="安装位置" prop="positionType">
              <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.positionType" placeholder="请选择安装位置">
                <el-option v-for="(item, index) in searchList[1].list" :key="index" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家">
              <el-input :disabled="type == '2'" placeholder="请输入生产厂家" clearable
                v-model.trim="ruleForm.producerName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生产日期">
              <el-date-picker @change="convertToDate" :disabled="type == '2'" v-model="ruleForm.producerDate"
                :append-to-body="false" type="date" value-format="yyyy-MM-dd" placeholder="选择生产日期"
                class="operationDate">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="投运日期">
              <el-date-picker @change="convertToDate" :disabled="type == '2'" v-model="ruleForm.operationDate"
                :append-to-body="false" type="date" value-format="yyyy-MM-dd" placeholder="选择投运日期"
                class="operationDate">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="质保截止日期">
              <el-date-picker @change="convertToDate" :disabled="type == '2'" v-model="ruleForm.warrantyDate"
                :append-to-body="false" type="date" value-format="yyyy-MM-dd" placeholder="选择质保截止日期"
                class="operationDate">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="equName">
              <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.equName" placeholder="请选择设备名称">
                <el-option v-for="(item, index) in searchList[2].list" :key="index" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="equNum">
              <el-input :disabled="type == '2'" placeholder="请输入设备编号" clearable
                v-model.trim="ruleForm.equNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号" prop="equModel">
              <el-input :disabled="type == '2'" placeholder="请输入设备型号" clearable
                v-model.trim="ruleForm.equModel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="计量方式" prop="">
              <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.meteringType" placeholder="请选择计量方式">
                <el-option v-for="(item, index) in searchList[3].list" :key="index" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="单位名称" prop="companyInfoId">
              <el-select :disabled="type == '2'" clearable class="custom-select" :popper-append-to-body="false"
                v-model="ruleForm.companyInfoId" placeholder="请选择单位名称">
                <el-option v-for="(item, index) in searchList[4].list" :key="index" :label="item.companyName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="缴费单位:" props="companyInfoId" class="myDialogForm">
              <!-- <el-cascader :disabled="type == '2'" class="mycascader" popper-class="cascaderPopper" clearable
                v-model="ruleForm.companyInfoId" :options="Hierarchy" :props="HierarchyProps" change-on-select
                expand-trigger="click" @change="setSelectedNode"></el-cascader> -->
                <el-input :disabled="type == '2'" placeholder="请输入缴费单位" clearable
                v-model.trim="ruleForm.payUnit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="缴费用户名:" prop="">
              <el-input :disabled="type == '2'" placeholder="请输入缴费用户名" clearable
                v-model.trim="ruleForm.payName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="缴费用户编号:" prop="">
              <el-input :disabled="type == '2'" placeholder="请输入缴费用户编号" clearable
                v-model.trim="ruleForm.payNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="外电源电压等级:" prop="">
              <el-input :disabled="type == '2'" placeholder="请输入外电源电压等级" clearable
                v-model.trim="ruleForm.electricLevel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
           
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" v-if="type != '2'" class="dialog-footer">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="listAdd('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogTextDelete == 1 ? '删除' : '下载'" :visible.sync="centerDialogVisible" width="20%">
      <div class="deleteCenter">
        {{ dialogTextDelete == 1 ? '确定删除该条信息?' : '确定下载该文件?' }}
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
    <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" :loading="btnLoading" @click="downLoadExport">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog @close="fileDialog" title="导入" :visible.sync="uploadFileDialog" width="25%">
      <div class="newCenter">
        <el-form :model="ruleForm1" ref="ruleForm1" label-width="100px" class="update-ruleForm">
          <el-form-item label="文件">
            <el-input :disabled="true" v-model="ruleForm1.dictCode" class="update-input"
              placeholder="请选择以模版文件为蓝本需要导入的数据文件">
              <template slot="append">
                <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="(file) => {
                  imgUpload1(file, FileList);
                }
                  " :limit="2" multiple :file-list="FileList">
                  选择文件
                </el-upload>
              </template>
            </el-input>
            <div class="fileUploadPrompt">下载模版填写数据后进行导入</div>
          </el-form-item>


        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="uploadFileDialog = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" :disabled="ruleForm1.dictCode == ''" @click="fileUpload">确
          定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="下载" :visible.sync="templateFile" width="20%">
      <div class="deleteCenter">
        确定下载该模版文件
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="templateFile = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="downloadTemplateFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { assetInfoIndex, assetInfoUPdateFile, downloadTemplate, assetInfoAdd, assetInfoUpdate, assetInfoExport, assetInfoDelete, searchDictCodeList, unitList } from '../../api/equipment.js'
import { queryCompanyHierarchy } from "@/api/eios.js"
// import demo from "./demo.vue"
export default {
  name: 'Administration',
  data() {
    return {
      FileList: [],
      file: {},
      formInline: {
        equName: '',//设备名称
        equNum: '',//资产编号

      },
      tableData: [
      ],
      centerDialogVisible: false,
      replyDialogVisible: false,
      uploadFileDialog: false,
      templateFile: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: 1,
      pageSize: 10,
      currentPage: 1,
      total: 40,
      textarea: '',
      ruleForm1: {
        dictCode: '',
      },
      ruleForm: {
        assetNum: '',//资产编号
        stationName: '',//场站名称
        stationNum: '',//场站编码
        // stationNickname: '',//电站名称
        positionType: '',//安装位置
        producerName: '',//生产厂家
        transformerCapacity: '',//变压器总容量（KVA）  
        type: '',//配电形式
        positionType: '',//安装位置
        producerName: '',//生产厂家
        producerDate: null,//生产日期
        operationDate: null,//投运日期
        warrantyDate: null,//质保截止日期
        equName: '',//设备名称
        equNum: '',//设备编号
        equModel: '',//设备型号
        meteringType: '',//计量方式
        // companyInfoId: '',//单位名称
        payUnit: '',//缴费单位
        electricLevel: '',//外电源电压等级
        electricStructure: '',//外电源结构
        payName: '',//缴费用户名
        payNum: '',//缴费用户编号
        nature: '',//用电性质
        deviceOrder:'',//设备数量
      },

      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      dialogTextDelete: 0,
      id: '',
      type: '',
      dataLoading: false,
      searchList: [
        {
          name: '配电形式',
          list: [],
        },
        {
          name: '安装位置',
          list: [],
        },
        {
          name: '设备名称',
          list: [],
        },
        {
          name: '计量方式',
          list: []
        },
        {
          name: '外电源结构',
          list: []
        },
        {
          name: '单位信息',
          list: []
        },
      ],

      dictCodeList: [
        {
          name: '配电形式',
          dictCode: 'station_type',

        },
        {
          name: '安装位置',
          dictCode: 'position_type'
        },
        {
          name: '设备名称',
          dictCode: 'equ_name'
        },
        {
          name: '计量方式',
          dictCode: 'metering_type'
        },
        {
          name: '外电源结构',
          dictCode: 'waidianyuanjiegou'
        },
      ],
      btnLoading: false,
      Hierarchy: [],
      HierarchyProps: {
        children: 'companyInfoList',
        value: 'id',
        label: 'companyName'
      },
      rules: {
        assetNum: [
          { required: true, message: '请输入资产编号', trigger: 'blur' },
        ],
        stationName: [
          { required: true, message: '请输入场站名称', trigger: 'blur' },
        ],
        stationNum: [ 
          { required: true, message: '请输入场站编码', trigger: 'blur' },
        ],
        deviceOrder: [ 
          { required: true, message: '请输入设备数量', trigger: 'blur' },
        ],
        transformerCapacity: [
          { required: true, message: '请选择变压器总容量', trigger: 'change' },
        ],
        type: [
          { required: true, message: '请选择配电形式', trigger: 'change' },
        ],
        equName: [
          { required: true, message: '请选择资产名称', trigger: 'change' },
        ],
        equNum: [
          { required: true, message: '请输入设备编号', trigger: 'blur' },
        ],
        equModel: [
          { required: true, message: '请输入设备型号', trigger: 'blur' },
        ],
        companyInfoId: [
          { required: true, message: '请选择单位名称', trigger: 'change' },
        ],
        positionType: [
          { required: true, message: '请选择安装位置', trigger: 'change' },
        ],
      },
      authorityListBtn: [],
      dialogTitle: '新建资产'

    };
  },
  // components:{demo},
  filters: {
    getName(id, list) {
      const item = list.find(item => item.dictValue === id);
      return item ? item.dictName : '';
    },
  },

  mounted() {

    this.getList()
    this.getDictList()
    this.getCom()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')

  },
  created() {
    this.$store.commit('increment', '资产管理')
    this.$store.commit('selectChild', '')
    this.$store.commit('selectChildren', '')
    // this.getList()
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    treeProps() {
      return {
        children: 'children',
        label: 'label',
        isLeaf: node => !node.children || node.children.length === 0,
      };
    },
  },


  methods: {

    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    //获取单位层级
    getCom() {
      queryCompanyHierarchy().then(res => {
        this.Hierarchy = res.data
      })
    },
    //获取父级最后一集
    setSelectedNode(val) {
      this.ruleForm.companyInfoId = val[val.length - 1];
    },
    //日期校验
    convertToDate() {
      let time1 = ''
      let time2 = ''
      let time3 = ''
      if (this.ruleForm.producerDate) {
        time1 = new Date(this.ruleForm.producerDate).getTime();
      }
      if (this.ruleForm.operationDate) {
        time2 = new Date(this.ruleForm.operationDate).getTime();
      }
      if (this.ruleForm.warrantyDate) {
        time3 = new Date(this.ruleForm.warrantyDate).getTime();
      }
      console.log(time1, time2, time3);

      if (this.ruleForm.producerDate && this.ruleForm.operationDate) {
        if (time1 > time2) {
          this.$message({
            message: '生产日期不得大于投运日期',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.ruleForm.operationDate = null
        }
      }
      if (this.ruleForm.producerDate && this.ruleForm.warrantyDate) {
        if (time1 > time3) {
          this.$message({
            message: '生产日期不得大于质保截止日期',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.ruleForm.warrantyDate = null
        }
      }

    },
    fileDialog() {
      this.FileList = []
      this.ruleForm1.dictCode = ''
      this.file = null
    },
    //过滤
    getName(id, list) {
      const item = list.find(item => item.id === id);
      return item ? item.name : '';
    },
    //获取字典的值
    getDictList() {

      for (let i = 0; i < this.dictCodeList.length; i++) {

        searchDictCodeList({ dictCode: this.dictCodeList[i].dictCode }).then(res => {

          if (res.code == 200) {
            if (this.searchList[i].name == this.dictCodeList[i].name) {
              this.searchList[i].list = res.data
            }
          }
        })

      }
      unitList().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.searchList[5].list = res.data
        }
      })
      console.log(this.searchList);


    },



    //导出/删除
    downLoadExport() {
      if (this.dialogTextDelete == 0) {
        this.btnLoading = true
        let obj = {
          deviceName: this.formInline.equName,
          deviceNumber: this.formInline.equNum,

        }

        assetInfoExport(obj).then(res => {
          console.log("1111111");
          if (!res.code) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([res]));
            link.download = decodeURIComponent('资产管理.xls'); // 解码文件名称，避免名称中含有中文时乱码
            link.click();
            this.centerDialogVisible = false
            this.btnLoading = false
          }
        })
      }
      //删除
      if (this.dialogTextDelete == 1) {
        assetInfoDelete(this.id).then(res => {
          if (res.code == 200) {
            this.centerDialogVisible = false
            this.getList()
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })
      }
    },
    dialogClose() {
      this.ruleForm = this.$options.data().ruleForm
      this.$refs.ruleForm.resetFields();
      // this.$refs.ruleForm.clearValidate();
    },
    //新增/修改 
    listAdd(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let obj = {
            ...this.ruleForm,
            // id:this.id
          }
          let func = this.type == '0' ? assetInfoAdd(this.ruleForm) : assetInfoUpdate(obj)
          func.then(res => {
            if (res.code == 200) {
              this.replyDialogVisible = false,
                this.ruleForm = this.$options.data().ruleForm
              this.getList()
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //下载模版
    downloadTemplateFile() {
      downloadTemplate().then(res => {
        if (!res.code) {
          // console.log(res);
          const link = document.createElement('a');
          // const filename = res.headers['Content-Disposition'].split('filename=')[1]; // 获取文件名称
          link.href = URL.createObjectURL(new Blob([res]));
          link.download = decodeURIComponent('资产管理模版.xls'); // 解码文件名称，避免名称中含有中文时乱码
          link.click();
          this.templateFile = false
          // this.centerDialogVisible1 = false
        }
      })
    },
    downloadFileTemplate() {
      this.templateFile = true
    },

    uploadFile() {
      this.uploadFileDialog = true
    },
    //上传
    imgUpload1(file, FileList) {
      // console.log("111111111");
      this.FileList = FileList
      console.log(file);
      let suffix = file.name.split('.')[file.name.split('.').length - 1]
      console.log(suffix);
      if (suffix == 'xls' || suffix == 'xlsx') {
        this.ruleForm1.dictCode = file.name
        this.file = file
      } else {
        this.$message({
          message: '请使用下载模版填写数据进行导入',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });

      }
    },
    fileUpload() {
      let file = this.file
      console.log("wenjian ~~~~", file)
      let formData = new FormData();
      formData.append("file", file.raw);
      assetInfoUPdateFile(formData).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.uploadFileDialog = false
          this.ruleForm1.dictCode = ''
          this.getList()
          this.file = null
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    getIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    // tree搜索
    handleSearch() {

    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      this.updateSelectAllState();
    },
    handleSelectAll(checked) {
      this.selectAll = checked;
      this.$refs.tree.setCheckedKeys(checked ? this.getAllNodeKeys() : []);
      this.updateSelectAllText();
    },
    getAllNodeKeys() {
      const keys = [];
      const traverse = node => {
        keys.push(node.id);
        if (node.children) {
          node.children.forEach(child => traverse(child));
        }
      };
      this.treeData.forEach(node => traverse(node));
      return keys;
    },
    updateSelectAllState() {
      const allNodes = this.$refs.tree.getCheckedNodes({ leafOnly: false });
      this.selectAll = allNodes.length === this.getAllNodeKeys().length;
      this.updateSelectAllText();
    },
    updateSelectAllText() {
      this.selectAllText = this.selectAll ? '取消全选' : '全选';
    },
    //列表查询
    getList() {
      this.dataLoading = true
      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      assetInfoIndex(obj).then(res => {
        // console.log(res);
        this.dataLoading = false
        if (res.code == 200) {
          this.tableData = res.data
          this.total = res.total
        }
      })
    },
    //修改/查看
    replyDialog(el, type) {
      if (type == '1') {
        this.dialogTitle = '编辑资产'
      } else {
        this.dialogTitle = '详情'
      }
      console.log(el.data);
      this.id = el.id,
        this.type = type,
        /**
         * 
         *  ruleForm:{
          equName:'',
          meteringType:'',
          type:'',
          positionType:'',
        }
         */
        this.ruleForm = Object.assign({}, el);
      // this.ruleForm.



      this.replyDialogVisible = true
    },
    clickTable(el, type) {
      this.centerDialogVisible = true
      this.dialogTextDelete = type
      this.id = el.id
    },

    //查询列表
    onSubmit() {
      console.log('submit!');
      this.getList()
    },

    //打开意见弹窗
    openViewDialog(type) {
      this.dialogTitle = '新建资产'
      this.type = type
      this.replyDialogVisible = true
    },
    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    //输入校验
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd() {
      console.log(this.paginationEndIpt);
      const lastPage = Math.ceil(this.total / this.pageSize);
      if (this.paginationEndIpt < 1) {
        this.paginationEndIpt = 1
      } else {
        this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
      }
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
      this.getList()
    },
    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    }


  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 23px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  /* color: #d7d7d7; */
  border-radius: 2px;
  border: 1px solid #0b2653;
  color: #AED6FF;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061B45;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  /* color: #667597; */
}


.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}

::v-deep .is-current {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.update-input>>>.el-input__inner {
  width: 100%;
}

.update-input>>>.el-input__inner {
  color: #667597;
}

.update-ruleForm {
  width: 100%;
}

.operationDate {
  width: 100%;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #061D4A;
}

.fileUploadPrompt {
  color: #aed6ff;
}

::v-deep .el-input.is-disabled .el-input__inner {
  border-color: #09295b;
}

.mycascader>>>.el-input__inner {
  background-color: #061B45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}



.mycascader .el-cascader {
  width: 100%;
}

.mycascader {
  width: 100%;
}
.form :deep() .el-input input::placeholder {
  color: #6180a8;
}

:deep() .el-input input::placeholder {
  color: #6180a8;
}

:deep() .el-textarea .el-textarea__inner::placeholder {
  color: #6180a8;
}
</style>
<style>
.cascaderPopper {
  background-color: #061D4A;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.cascaderPopper .el-cascader-menu {
  color: #aed6ff;

}

.cascaderPopper .el-cascader-node:hover {
  background: #09224B !important;
}

.cascaderPopper .el-cascader-node:focus {
  background: #09224B !important;
}

.cascaderPopper .popper__arrow::after {
  border-bottom-color: #071734 !important;
}

.cascaderPopper {
  width: 600px;
  overflow: auto;

}

.cascaderPopper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

.cascaderPopper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>